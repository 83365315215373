export function getProviderNames() {
  const providerNames: { [key: string]: string } = {};

  for (const providers of Object.values(ELECTRICITY_PROVIDERS)) {
    for (const [code, details] of Object.entries(providers)) {
      providerNames[code] = details.name;
    }
  }

  return providerNames;
}

type ElectricityProviders = { [key: string]: { [key: string]: { name: string } } };
const ELECTRICITY_PROVIDERS: ElectricityProviders = {
  '80002': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80003': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80004': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80005': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80007': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80010': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80011': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80012': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80013': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80014': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80015': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80016': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80017': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80018': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80019': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80020': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80021': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80022': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80023': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80024': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80025': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80026': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80027': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80030': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80031': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80033': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80045': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80101': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80102': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80103': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80104': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80105': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80106': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80107': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80108': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80109': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80110': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80111': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80112': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80113': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80116': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80117': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80118': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80120': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80121': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80122': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80123': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80124': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80125': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80126': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80127': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80128': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80129': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80130': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80131': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80132': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80133': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80134': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80135': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80136': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80137': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80138': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80202': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80203': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80204': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80205': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80206': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80207': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80209': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80210': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80211': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80212': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80214': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80215': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80216': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80218': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80219': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80220': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80221': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80222': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80223': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80224': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80226': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80227': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80228': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80229': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80230': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80231': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80232': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80233': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80234': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80235': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80236': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80237': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80238': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80239': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80241': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80246': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80247': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80249': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80260': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80264': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80290': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80293': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80294': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80301': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80302': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80303': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80304': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80305': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80310': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80401': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80403': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80419': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80420': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80421': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80422': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80423': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80424': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80425': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80426': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80427': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80428': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80432': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80433': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80434': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
  },
  '80435': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80436': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80438': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80439': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80440': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80442': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
  },
  '80443': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80444': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80446': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80447': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80448': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80449': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
  },
  '80451': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
  },
  '80452': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80453': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80454': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80455': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80456': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80457': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80459': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80461': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80463': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80465': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80466': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80467': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80468': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80469': {
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80470': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80471': {
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80473': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
  },
  '80475': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80476': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80477': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80478': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
  },
  '80479': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80480': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80481': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80482': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80483': {
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80487': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80488': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80497': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80498': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '80501': {
    'co-longmont-power-and-communications': {
      name: 'Longmont Power & Communications',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80503': {
    'co-longmont-power-and-communications': {
      name: 'Longmont Power & Communications',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80504': {
    'co-longmont-power-and-communications': {
      name: 'Longmont Power & Communications',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80510': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80511': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-estes-park-power-and-communications': {
      name: 'Estes Park Power and Communications',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80512': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80513': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80514': {
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80515': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80516': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80517': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-estes-park-power-and-communications': {
      name: 'Estes Park Power and Communications',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80520': {
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80521': {
    'co-fort-collins-utilities': {
      name: 'Fort Collins Utilities',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80524': {
    'co-fort-collins-utilities': {
      name: 'Fort Collins Utilities',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80525': {
    'co-fort-collins-utilities': {
      name: 'Fort Collins Utilities',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80526': {
    'co-fort-collins-utilities': {
      name: 'Fort Collins Utilities',
    },
    'co-loveland-water-and-power': {
      name: 'Loveland Water and Power',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80528': {
    'co-fort-collins-utilities': {
      name: 'Fort Collins Utilities',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80530': {
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80532': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
  },
  '80534': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80535': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80536': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80537': {
    'co-loveland-water-and-power': {
      name: 'Loveland Water and Power',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80538': {
    'co-loveland-water-and-power': {
      name: 'Loveland Water and Power',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80540': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80542': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80543': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80544': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80545': {
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80546': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
  },
  '80547': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80549': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80550': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80601': {
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80602': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80603': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80610': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80611': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80612': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
  },
  '80615': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80620': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80621': {
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80622': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80623': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80624': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80631': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80634': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80640': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80642': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80643': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80644': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80645': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80648': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-united-power': {
      name: 'United Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80649': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80650': {
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80651': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-united-power': {
      name: 'United Power',
    },
  },
  '80652': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80653': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80654': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80701': {
    'co-city-of-fort-morgan': {
      name: 'City of Fort Morgan',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80705': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80720': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80721': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80722': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80723': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80726': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80727': {
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80728': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80729': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-poudre-valley-rea': {
      name: 'Poudre Valley REA',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '80731': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80733': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80734': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80735': {
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80736': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80737': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80740': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80741': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80742': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80743': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80744': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80745': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80746': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80747': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80749': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
  },
  '80750': {
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80751': {
    'co-high-west-energy': {
      name: 'High West Energy',
    },
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80754': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
  },
  '80755': {
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80757': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80758': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80759': {
    'co-highline-electric-association': {
      name: 'Highline Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80801': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80802': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-wheatland-electric-cooperative': {
      name: 'Wheatland Electric Cooperative',
    },
  },
  '80804': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80805': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
  },
  '80807': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80808': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '80809': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80810': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-wheatland-electric-cooperative': {
      name: 'Wheatland Electric Cooperative',
    },
  },
  '80812': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80813': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80814': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80815': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80816': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80817': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-city-of-fountain': {
      name: 'City of Fountain',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80818': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-morgan-county-rea': {
      name: 'Morgan County REA',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80819': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80820': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
  },
  '80821': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80822': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80823': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '80824': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80825': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '80827': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80828': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80829': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80830': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80831': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80832': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80833': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '80834': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-y-w-electric-association': {
      name: 'Y-W Electric Association',
    },
  },
  '80835': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80836': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
  },
  '80840': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80860': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '80861': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
  },
  '80862': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80863': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
  },
  '80864': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '80902': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80903': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80904': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80905': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80906': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80907': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80908': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80909': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80910': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80911': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-city-of-fountain': {
      name: 'City of Fountain',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80913': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80914': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
  },
  '80915': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80916': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80917': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80918': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80919': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80920': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80921': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80922': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80923': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80924': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80925': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80926': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80927': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80928': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '80929': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80930': {
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80938': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80939': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '80951': {
    'co-colorado-springs-utilities': {
      name: 'Colorado Springs Utilities',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '81001': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
  },
  '81003': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '81004': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81005': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81006': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81007': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81008': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81019': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81020': {
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81021': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81022': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81023': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81024': {
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81025': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81027': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-southwestern-electric-cooperative': {
      name: 'Southwestern Electric Cooperative',
    },
  },
  '81029': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-tri-county-electric-cooperative': {
      name: 'Tri-County Electric Cooperative',
    },
  },
  '81030': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-la-junta-municipal-utilities': {
      name: 'La Junta Municipal Utilities',
    },
  },
  '81033': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '81036': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81038': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81039': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81040': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81041': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81043': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81044': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81045': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81047': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-wheatland-electric-cooperative': {
      name: 'Wheatland Electric Cooperative',
    },
  },
  '81049': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-southwestern-electric-cooperative': {
      name: 'Southwestern Electric Cooperative',
    },
  },
  '81050': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81052': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81054': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81055': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81057': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81058': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '81059': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81062': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81063': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-mountain-view-electric-association': {
      name: 'Mountain View Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81064': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-southwestern-electric-cooperative': {
      name: 'Southwestern Electric Cooperative',
    },
    'co-tri-county-electric-cooperative': {
      name: 'Tri-County Electric Cooperative',
    },
  },
  '81067': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81069': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81071': {
    'co-kc-electric-association': {
      name: 'K.C. Electric Association',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-wheatland-electric-cooperative': {
      name: 'Wheatland Electric Cooperative',
    },
  },
  '81073': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81076': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81077': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '81081': {
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81082': {
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81084': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81087': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81089': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81090': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
    'co-tri-county-electric-cooperative': {
      name: 'Tri-County Electric Cooperative',
    },
  },
  '81091': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81092': {
    'co-southeast-colorado-power-association': {
      name: 'Southeast Colorado Power Association',
    },
  },
  '81101': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81120': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81121': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
  },
  '81122': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81123': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81124': {
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81125': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81126': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81128': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81129': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81130': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81131': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81132': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81133': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
  },
  '81136': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81137': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
  },
  '81138': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81140': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81141': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81143': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81144': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81146': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81147': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81148': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81149': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81151': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81152': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
  },
  '81154': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81155': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81201': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81210': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81211': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81212': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
  },
  '81220': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81221': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '81222': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81223': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81224': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81225': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
  },
  '81226': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
  },
  '81227': {
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
  },
  '81230': {
    'co-city-of-gunnison': {
      name: 'City of Gunnison',
    },
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81231': {
    'co-city-of-gunnison': {
      name: 'City of Gunnison',
    },
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81232': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
  },
  '81233': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81235': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-luis-valley-rec': {
      name: 'San Luis Valley REC',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81236': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81237': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
  },
  '81239': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
  },
  '81240': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '81241': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
  },
  '81242': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81243': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81244': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
  },
  '81248': {
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
  },
  '81251': {
    'co-core-electric-cooperative': {
      name: 'CORE Electric Cooperative',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81252': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81253': {
    'co-black-hills-energy': {
      name: 'Black Hills Energy',
    },
    'co-san-isabel-electric': {
      name: 'San Isabel Electric',
    },
    'co-sangre-de-cristo-electric-association': {
      name: 'Sangre de Cristo Electric Association',
    },
  },
  '81301': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81303': {
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
  },
  '81320': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81321': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
  },
  '81323': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
  },
  '81324': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81325': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81326': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
  },
  '81327': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
  },
  '81328': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-la-plata-electric-association': {
      name: 'La Plata Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81330': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
  },
  '81331': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
  },
  '81332': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81334': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
  },
  '81335': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
  },
  '81401': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81403': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81410': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
  },
  '81411': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81413': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81415': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
  },
  '81416': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81418': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81419': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81422': {
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81423': {
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81424': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81425': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81426': {
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81427': {
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81428': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
  },
  '81429': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81430': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81431': {
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81432': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81433': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81434': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81435': {
    'co-empire-electric-association': {
      name: 'Empire Electric Association',
    },
    'co-san-miguel-power-association': {
      name: 'San Miguel Power Association',
    },
  },
  '81501': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81503': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81504': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81505': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81506': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81507': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81520': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81521': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81522': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81523': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81524': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81525': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81526': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81527': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81601': {
    'co-glenwood-springs-electric': {
      name: 'Glenwood Springs Electric',
    },
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81610': {
    'co-moon-lake-electric-association': {
      name: 'Moon Lake Electric Association',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81611': {
    'co-city-of-aspen-electric': {
      name: 'City of Aspen Electric',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81612': {
    'co-city-of-aspen-electric': {
      name: 'City of Aspen Electric',
    },
  },
  '81615': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
  },
  '81620': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81621': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81623': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-gunnison-county-electric-association': {
      name: 'Gunnison County Electric Association.',
    },
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81624': {
    'co-delta-montrose-electric-association': {
      name: 'Delta Montrose Electric Association',
    },
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81625': {
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81630': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-moon-lake-electric-association': {
      name: 'Moon Lake Electric Association',
    },
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81631': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81632': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81633': {
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81635': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81637': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81638': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81639': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81640': {
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81641': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81642': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81643': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
  },
  '81645': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81646': {
    'co-grand-valley-power': {
      name: 'Grand Valley Power',
    },
  },
  '81647': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81648': {
    'co-moon-lake-electric-association': {
      name: 'Moon Lake Electric Association',
    },
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81649': {
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81650': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-moon-lake-electric-association': {
      name: 'Moon Lake Electric Association',
    },
    'co-white-river-electric-association': {
      name: 'White River Electric Association',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81652': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81653': {
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81654': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
  },
  '81655': {
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '81656': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
  },
  '81657': {
    'co-holy-cross-energy': {
      name: 'Holy Cross Energy',
    },
    'co-mountain-parks-electric': {
      name: 'Mountain Parks Electric',
    },
    'co-xcel-energy': {
      name: 'Xcel Energy',
    },
    'co-yampa-valley-electric-association': {
      name: 'Yampa Valley Electric Association',
    },
  },
  '82063': {},
};

export default ELECTRICITY_PROVIDERS;
